import React, { useState } from 'react';
import axios from 'axios';
import { Form, Input, Button, message, Row, Col, Card } from 'antd';

const LoginSettings = () => {
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const onFinish = async () => {
        try {
            if (password !== password2) {
                throw new Error('Passwords do not match');
            }

            const accessToken = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');

            if (!accessToken || typeof accessToken !== 'string' || !refreshToken || typeof refreshToken !== 'string') {
                 window.location.href = '/dashboard';
                 return alert('Password changed successfully');
                
            }

            const response = await axios.post(
                '/api/changepassword/',
                {
                    password: password,
                    password2: password2
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );

            if (response.status === 200) {
                message.success('Password updated successfully');
            }
        } catch (error) {
            console.error("Error updating password:", error.response ? error.response.data : error);
            message.error('Error updating password');
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handlePassword2Change = (e) => {
        setPassword2(e.target.value);
    };

    return (
        <Row justify="center" align="middle" style={{ minHeight: '10vh', padding: '20px' }}>
            <Col xs={24} sm={16} md={10} lg={8}>
                <Card title="Login Settings" bordered={false}>
                    <Form
                        name="loginSettings"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        layout="vertical"
                    >
                        <Form.Item
                            label="New Password"
                            name="newPassword"
                            rules={[{ required: true, message: 'Please input your new password!' }]}
                        >
                            <Input.Password onChange={handlePasswordChange} />
                        </Form.Item>

                        <Form.Item
                            label="Confirm New Password"
                            name="confirmPassword"
                            rules={[
                                { required: true, message: 'Please confirm your new password!' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The two passwords do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password onChange={handlePassword2Change} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                Update Password
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default LoginSettings;
