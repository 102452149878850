import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, Col, Row, Statistic } from 'antd';

const DataView = () => {
    const [stats, setStats] = useState(null);

    useEffect(() => {
        axios.get("/api/patient_stats")
            .then(response => {
                setStats(response.data);
                console.log("Fetched patient statistics:", response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the patient statistics:", error);
            });
    }, []);

    if (!stats) {
        return <div className="loading">Loading statistics...</div>;
    }

const toFixedSafe = (value, digits = 2) => value ? Number(value).toFixed(digits) : 'N/A';

return (
    <div>
        <Row gutter={[24, 24]} className="data-view-row">
            <Col span={8}>
                <Card className="data-card">
                    <div className="title-and-value">
                        <div className="statistic-title">Total Patients</div>
                        <Statistic value={stats.total_patients} className="statistic-value" />
                    </div>
                    <div>Male: <b>{stats.gender_count.male}</b></div>
                    <div>Female: <b>{stats.gender_count.female}</b></div>
                </Card>
            </Col>

            <Col span={8}>
                <Card className="data-card">
                    <div className="title-and-value">
                        <div className="statistic-title">Record in Last 7 Days</div>
                        <Statistic value={stats.records_last_7_days_by_gender.Male + stats.records_last_7_days_by_gender.Female} className="statistic-value" />
                    </div>
                    <div>Male: <b>{stats.records_last_7_days_by_gender.Male}</b></div>
                    <div>Female: <b>{stats.records_last_7_days_by_gender.Female}</b></div>
                </Card>
            </Col>

            <Col span={8}>
                <Card className="data-card">
                    <div className="title-and-value">
                        <div className="statistic-title">Records This Year</div>
                        <Statistic value={stats.records_this_year_by_gender.Male + stats.records_this_year_by_gender.Female} className="statistic-value" />
                    </div>
                    <div>Male: <b>{stats.records_this_year_by_gender.Male}</b></div>
                    <div>Female: <b>{stats.records_this_year_by_gender.Female}</b></div>
                </Card>
            </Col>

            <Col span={12}>
                <Card className="data-card">
                    <div className="statistic-title">Avg. Age</div>
                    <Statistic value={stats.overall_average_age} suffix="yrs" precision={2} className="statistic-value" />
                    <div>Male: <b>{toFixedSafe(stats.average_age_by_gender.Male)} yrs</b></div>
                    <div>Female: <b>{toFixedSafe(stats.average_age_by_gender.Female)} yrs</b></div>
                </Card>
            </Col>

            <Col span={12}>
                <Card className="data-card">
                    <div className="statistic-title">Smokers Percentage</div>
                    <Statistic value={(stats.smokers_percentage_by_gender.Male + stats.smokers_percentage_by_gender.Female) / 2} precision={2} suffix="%" className="statistic-value" />
                    <div>Male: <b>{toFixedSafe(stats.smokers_percentage_by_gender.Male)}%</b></div>
                    <div>Female: <b>{toFixedSafe(stats.smokers_percentage_by_gender.Female)}%</b></div>
                </Card>
            </Col>


            <Col span={8}>
                <Card className="data-card">
                    <div className="statistic-title">Avg. Total Cholesterol</div>
                    <Statistic value={stats.overall_average_total_cholesterol} suffix="mg/DL" precision={2} className="statistic-value" />
                    <div>Male: <b>{stats.average_cholesterol_by_gender.Male.toFixed(2)} mg/DL</b></div>
                    <div>Female: <b>{stats.average_cholesterol_by_gender.Female.toFixed(2)} mg/DL</b></div>
                </Card>
            </Col>

            <Col span={8}>
                <Card className="data-card">
                    <div className="statistic-title">Avg. HDL Cholesterol</div>
                    <Statistic value={stats.overall_average_hdl_cholesterol} suffix="mg/DL" precision={2} className="statistic-value" />
                    <div>Male: <b>{stats.average_hdl_cholesterol_by_gender.Male.toFixed(2)} mg/DL</b></div>
                    <div>Female: <b>{stats.average_hdl_cholesterol_by_gender.Female.toFixed(2)} mg/DL</b></div>
                </Card>
            </Col>

            <Col span={8}>
                <Card className="data-card">
                    <div className="statistic-title">Avg. Blood Pressure</div>
                    <Statistic value={stats.overall_average_blood_pressure} suffix="mmHg"className="statistic-value" />
                    <div>Male: <b>{stats.average_blood_pressure_by_gender.Male.toFixed(2)} mmHg</b></div>
                    <div>Female: <b>{stats.average_blood_pressure_by_gender.Female.toFixed(2)} mmHg</b></div>
                </Card>
            </Col>
        </Row>
        <Row gutter={[24, 24]} className="data-view-row">
            {Object.entries(stats.risk_distribution_by_age_gender || {}).map(([ageGroup, values], index) => (
                <Col key={index} span={8}>
                    <Card className="data-card">
                        <div className="statistic-title">Avg. Risk Distribution: {ageGroup} yrs</div>
                        {Object.entries(values).map(([gender, risk], genderIndex) => (
                            <div key={genderIndex} className="statistic-info">{gender}: <b>{risk}%</b></div>
                        ))}
                    </Card>
                </Col>
            ))}
        </Row>
        </div>
    );
};

export default DataView;