import { Button, Card, Col, Row, Typography } from 'antd';
import { ToolOutlined } from '@ant-design/icons';

const { Title } = Typography;

function Home() {
  return (
    <Typography>
        <div>
            <Title className='title'>Cardiovascular Disease</Title>
            <div className='cardContainer'>
                <Card>
                    <p>Often abbreviated as CVD, refers to a class of diseases that involve the heart or blood vessels.</p>
                    <p>It's a broad term that covers a range of conditions affecting the heart and circulatory system.</p>
                </Card>
            </div>
        </div>

        <div>
            <Title className='title-level-3'>Main Factors</Title>
            <div className='cardContainer'>
                <Row gutter={16}>
                    <Col span={8}>
                    <Card title="High Blood Pressure" bordered={false}>
                        Elevated blood pressure levels over an extended period of time can strain the heart and blood vessels.
                    </Card>
                    </Col>

                    <Col span={8}>
                    <Card title="High Cholesterol" bordered={false}>
                        Excess cholesterol in the blood can lead to the formation of plaque in the arteries, hindering blood flow.
                    </Card>
                    </Col>
                    
                    <Col span={8}>
                    <Card title="Smoking" bordered={false}>
                        Tabacco use is a major risk factor for CVD, as it damages blood vessels and increases the risk of blood clots.
                    </Card>
                    </Col>
                </Row>

                <div className="centered-row">
                    <Row gutter={8} justify="center">
                        <Col span={8}>
                        <Card title="Unhealthy Diet" bordered={false}>
                            Poor dietary choices can contribute to the development of cardiovascular conditions.
                        </Card>
                        </Col>

                        <Col span={8}>
                        <Card title="Lack of Physical Activity" bordered={false}>
                            A sedentary lifestyle can lead to weight gain and weaken the heart and circulatory system.
                        </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>

        <div>
            <Title className='title-level-3' id='title-level-3'>Management & Prevention</Title>
            <div className='cardContainer'>
                <Card>
                    <p>Fortunately, many risk factors for CVD can be mitigated through lifetstyle changes.</p>
                    <p>Adopting a balanced diet, engaging in regular activities, avoiding tabacco and managing stress are essential steps in preventing CVD.</p>
                    <p>For those with existing CVD conditions, medical interventions, such as medication and surgical procedures, can help manage the disease and improve quality of life.</p>
                </Card>
            </div>
        </div>

        <div>
            <Title className='title-level-3' id='title-level-3'>Worried about CVD?</Title>
            <div className='finalCardContainer'>
                <Card>
                    <p>Don't delay, use our prediction tool to get an understanding of whether you should seek further medical advice.</p>
                </Card>
            </div>
        </div>

        <div>
            <Button onClick={event =>  window.location.href='/predictor'} size='large' type='primary' icon={<ToolOutlined />}>Predictor</Button>
        </div>
    </Typography>
  );
}

export default Home;