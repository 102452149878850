import { Card, Typography, Button, InputNumber, Form, Radio, Select } from "antd";
import axios from "axios";
import { useState } from "react";

const { Title } = Typography;
const { Option } = Select;

function Predictor() {
  const [apiResponse, setApiResponse] = useState(null);

  const onFinish = async (values) => {
    try {
      console.log("Form Values:", values);
      const response = await axios.post("/api/predictor", values);
      console.log("API Response:", response.data);
      setApiResponse(response.data);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  return (
    <Typography style={{ width: "100%" }}>
      <div>
        <Title className="title">Predictor</Title>
        <div className="finalCardContainer">
          <Card>
            <p>Please fill out all details as accurate as possible.</p>
            <p>Incorrect details may result in invalid results.</p>
          </Card>
        </div>
      </div>

      <div>
        <Form
          name="basic"
          labelCol={{
            xs: { span: 6 },
            sm: { span: 6 },
            lg: { span: 10 },
          }}
          wrapperCol={{
            xs: { span: 16 },
            sm: { span: 14 },
            lg: { span: 4 },
          }}
          style={{
            maxWidth: "99.9%",
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          onFinish={onFinish}
        >

          <Form.Item
            label="Age:"
            name="age"
            rules={[
              {
                type: 'number',
                required: true,
                message: "Your age must be between 20 & 80!",
                min: 20,
                max: 80
              },
            ]}
            className='custom-age-input'
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label="Assigned Gender at Birth"
            name="gender"
            rules={[
              {
                type: 'text',
                required: true,
                message: "You must selected your gender!"
              }
            ]}>
            <Radio.Group className='custom-radio-section'>
              <Radio.Button className="custom-radio-button" value="Male">Male</Radio.Button>
              <Radio.Button className="custom-radio-button" value="Female">Female</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="total_cholesterol"
            label="Total Cholesterol"
            rules={[
              {
                required: true,
                message: 'You must select the Cholesterol band you fall into!',
              },
            ]}
          >
            <Select placeholder="Select your Cholesterol band.">
              <Option value="159">Less Than 160 mg/dL</Option>
              <Option value="160">Between 160 - 199 mg/dL</Option>
              <Option value="200">Between 200 - 239 mg/dL</Option>
              <Option value="240">Between 240 - 279 mg/dL</Option>
              <Option value="280">More Than 280 mg/dL</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="hdl_cholesterol"
            label="HDL Cholesterol"
            rules={[
              {
                required: true,
                message: 'You must select the HDL Cholesterol band you fall into!',
              },
            ]}
          >
            <Select placeholder="Select your HDL Cholesterol band.">
              <Option value="39">Less Than 40 mg/dL</Option>
              <Option value="40">Between 40 - 49 mg/dL</Option>
              <Option value="50">Between 50 - 59 mg/dL</Option>
              <Option value="60">Move Than 60 mg/dL</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="blood_pressure"
            label="Systolic Blood Pressure"
            rules={[
              {
                required: true,
                message: 'You must select the Blood Pressure band you fall into!',
              },
            ]}
          >
            <Select placeholder="Select your Blood Pressure band.">
              <Option value="119">Less Than 120 mmHg</Option>
              <Option value="120">Between 120 - 129 mmHg</Option>
              <Option value="130">Between 130 - 139 mmHg</Option>
              <Option value="140">Between 140 - 159 mmHg</Option>
              <Option value="160">Between 160 - 179 mmHg</Option>
              <Option value="180">More Than 180 mmHg</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Smoker"
            name="is_smoker"
            rules={[
              {
                type: 'text',
                required: true,
                message: "You must select whether you smoke or not!"
              }
            ]}>
            <Radio.Group className='custom-radio-section'>
              <Radio.Button className="custom-radio-button" value="Yes">Yes</Radio.Button>
              <Radio.Button className="custom-radio-button" value="No">No</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 5,
              span: 16,
              xs: { span: 18 },
              sm: { span: 14 },
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>

        {apiResponse && (
          <div className="finalCardContainer">
            <Card>
              <p>Your Framingham Risk Score is: {apiResponse.score}</p>
              <p>Your estimated 10-year risk of developing CVD is: {apiResponse.risk}.</p>
            </Card>
          </div>
        )}
      </div>
    </Typography>
  );
}

export default Predictor;