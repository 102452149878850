import React, { useState } from "react";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Typography, message, Row, Col } from 'antd';
import axios from "axios";

const { Title } = Typography;

const StaffPortal = () => {
    const [apiResponse, setApiResponse] = useState(null);
    
    const onFinish = async (values) => {
        try {
            const response = await axios.post("/api/login/", values);
            if (response.data.token) {
                localStorage.setItem('token', response.data.token);
                console.log('Token:', response.data.token);
                window.location.href = '/dashboard';
            } else {
                console.log("No token found in the response");
                message.error("Invalid username or password");
            }
        } catch (error) {
            console.error("API Error:", error);
            message.error("The Username and Password is not correct");
        }
    };

    const formItemStyle = {
        maxWidth: "250px",
        margin: "0 auto",
        marginBottom: "20px",
    };

    return (
        <Row justify="center" align="middle" style={{ minHeight: '40vh' }}>
            <Col span={24}>
                <div>
                    <div className='staff-portal-title'>
                        <Title level={2} style={{ textAlign: 'center' }}>Staff Portal</Title>
                    </div>

                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        style={{ maxWidth: "300px", margin: "0 auto" }}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: 'Please input your Username!' }]}
                            style={formItemStyle}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                            style={formItemStyle}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <Form.Item style={formItemStyle}>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                            <a className="login-form-forgot" href="">
                                Forgot password
                            </a>
                        </Form.Item>
                        <Form.Item style={formItemStyle}>
                            <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '30%' }}>
                                Log In
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Col>
        </Row>
    );
}

export default StaffPortal;
