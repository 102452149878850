import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Pie, Bar, Column } from '@antv/g2plot';

const DataGraph = () => {
  const [pieData, setPieData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [riskData, setRiskData] = useState([]);
  const pieChartRef = useRef(null);
  const barChartRef = useRef(null);
  const columnChartRef = useRef(null);
  const riskChartRef = useRef(null);
  const pieChartInstance = useRef(null);
  const barChartInstance = useRef(null);
  const columnChartInstance = useRef(null);
  const riskChartInstance = useRef(null);

  useEffect(() => {
    axios.get("/api/patient_stats")
      .then(response => {
        const {
          gender_count,
          smokers_percentage_by_gender,
          average_age_by_gender,
          average_risk_score_by_gender,
          average_cholesterol_by_gender,
          average_hdl_cholesterol_by_gender,
          average_blood_pressure_by_gender,
          risk_distribution_by_age_gender
        } = response.data;

        const formattedPieData = Object.keys(gender_count).map(key => ({
          type: key.charAt(0).toUpperCase() + key.slice(1),
          value: gender_count[key],
        }));
        setPieData(formattedPieData);

        const formattedBarData = [
          { gender: 'Male', value: smokers_percentage_by_gender.male_smokers, category: 'Smoker' },
          { gender: 'Male', value: smokers_percentage_by_gender.male_non_smokers, category: 'Non-Smoker' },
          { gender: 'Female', value: smokers_percentage_by_gender.female_smokers, category: 'Smoker' },
          { gender: 'Female', value: smokers_percentage_by_gender.female_non_smokers, category: 'Non-Smoker' },
        ];
        setBarData(formattedBarData);

        const formattedColumnData = [
      
          { category: "Average Age (Yrs)", gender: "Male", value: average_age_by_gender.Male.toFixed(0) },
          { category: "Average Age (Yrs)", gender: "Female", value: average_age_by_gender.Female.toFixed(0) },
          { category: "Average Risk Score %", gender: "Male", value: average_risk_score_by_gender.find(item => item.gender === "Male")?.avg_risk.toFixed(2) },
          { category: "Average Risk Score %", gender: "Female", value: average_risk_score_by_gender.find(item => item.gender === "Female")?.avg_risk.toFixed(2) },    
          { category: "Average Cholesterol (mg/DL)", gender: "Male", value: average_cholesterol_by_gender.Male.toFixed(0) },
          { category: "Average Cholesterol (mg/DL)", gender: "Female", value: average_cholesterol_by_gender.Female.toFixed(0) },
          { category: "Average HDL Cholesterol (mg/DL)", gender: "Male", value: average_hdl_cholesterol_by_gender.Male.toFixed(0) },
          { category: "Average HDL Cholesterol (mg/DL)", gender: "Female", value: average_hdl_cholesterol_by_gender.Female.toFixed(0) },
          { category: "Average Blood Pressure (mmHg)", gender: "Male", value: average_blood_pressure_by_gender.Male.toFixed(0) },
          { category: "Average Blood Pressure (mmHg)", gender: "Female", value: average_blood_pressure_by_gender.Female.toFixed(0) },
        ];
        setColumnData(formattedColumnData);

        const formattedRiskData = [
          { category: "20-40 Yrs", gender: "Male", value: risk_distribution_by_age_gender["20-40"].Male.toFixed(2) },
          { category: "20-40 Yrs", gender: "Female", value: risk_distribution_by_age_gender["20-40"].Female.toFixed(2) },
          { category: "41-60 Yrs", gender: "Male", value: risk_distribution_by_age_gender["41-60"].Male.toFixed(2) },
          { category: "41-60 Yrs", gender: "Female", value: risk_distribution_by_age_gender["41-60"].Female.toFixed(2) },
          { category: "61-80 Yrs", gender: "Male", value: risk_distribution_by_age_gender["61-80"].Male.toFixed(2) },
          { category: "61-80 Yrs", gender: "Female", value: risk_distribution_by_age_gender["61-80"].Female.toFixed(2) },
        ]
        setRiskData(formattedRiskData);

      })

      .catch(error => {
        console.error("There was an error fetching the patient statistics:", error);
      });
  }, []);

  useEffect(() => {
    // Render or update Pie chart
    if (pieData.length > 0 && pieChartRef.current) {
      if (pieChartInstance.current) {
        pieChartInstance.current.changeData(pieData);
      } else {
        const pieChart = new Pie(pieChartRef.current, {
          appendPadding: 10,
          data: pieData,
          angleField: 'value',
          colorField: 'type',
          radius: 0.7,
          innerRadius: 0.6,
          label: {
            type: 'spider',
            labelHeight: 20,
            content: '{name}\n{percentage}%',
            style: {
              fontSize: 14,
            },
          },
          annotations: [{
            type: 'text',
            position: ['50%', '0%'],
            content: 'Patient Gender Distribution',
            style: {
              fontSize: 20,
              textAlign: 'center',
              fontWeight: 'bold',
            },
          }],
          interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
          theme: {
            colors10: ['#1f77b4', '#ffb6c1'],
          },
        });

        pieChart.render();
        pieChartInstance.current = pieChart;
      }
    }
  }, [pieData]);

  useEffect(() => {
    if (barData.length > 0 && barChartRef.current) {
      if (barChartInstance.current) {
        barChartInstance.current.changeData(barData);
      } else {
        const barChart = new Bar(barChartRef.current, {
          data: barData,
          isStack: true,
          xField: 'value',
          yField: 'gender',
          seriesField: 'category',
          color: ({ category }) => {
            if (category === 'Smoker') return 'darkgray';
            if (category === 'Non-Smoker') return 'lightgreen';
            return '#ccc';
          },
          label: {
            position: 'middle',
            layout: [
              { type: 'interval-adjust-position' },
              { type: 'interval-hide-overlap' },
              { type: 'adjust-color' },
            ],
          },
          annotations: [{
            type: 'text',
            position: ['50%', '0%'],
            content: 'Smokers and Non-Smokers by Gender',
            style: {
              fontSize: 16,
              textAlign: 'center',
              fontWeight: 'bold',
            },
          }],
        });

        barChart.render();
        barChartInstance.current = barChart;
      }
    }
  }, [barData]);

  useEffect(() => {
    console.log(columnData);
    if (columnData.length > 0 && columnChartRef.current) {
      if (columnChartInstance.current) {
        columnChartInstance.current.changeData(columnData);
      } else {
        const columnChart = new Column(columnChartRef.current, {
          data: columnData,
          isGroup: true,
          xField: 'category',
          yField: 'value',
          seriesField: 'gender',
          minColumnWidth: 20,
          maxColumnWidth: 40,
          label: {
            position: 'middle',
            layout: [
              { type: 'interval-adjust-position' },
              { type: 'interval-hide-overlap' },
              { type: 'adjust-color' },
            ],
          },
          theme: {
            colors10: ['#1f77b4', '#ffb6c1'],
          },
          annotations: [{
            type: 'text',
            position: ['50%', '0%'],
            content: 'Data Statistics',
            style: {
              fontSize: 16,
              textAlign: 'center',
              fontWeight: 'bold',
            },
          }],
          yAxis: {
            type: 'linear',
          },
          
        });
  
        columnChart.render();
        columnChartInstance.current = columnChart;
      }
    }
  }, [columnData]);

  useEffect(() => {
    if (riskData.length > 0 && riskChartRef.current) {
      if (riskChartInstance.current) {
        riskChartInstance.current.changeData(riskData);
      } else {
        const riskChart = new Bar(riskChartRef.current, {
          data: riskData,
          isGroup: true,
          xField: 'value',
          yField: 'category',
          xAxis: {
            type: 'linear',
          },
          seriesField: 'gender',
          label: {
            position: 'right',
            layout: [
              { type: 'interval-adjust-position' },
              { type: 'interval-hide-overlap' },
              { type: 'adjust-color' },
            ],
          },
          annotations: [{
            type: 'text',
            position: ['50%', '100%'],
            content: 'Risk Distribution by Age Group (%)',
            style: {
              fontSize: 16,
              textAlign: 'center',
              fontWeight: 'bold',
            },
          }],
        });
  
        riskChart.render();
        riskChartInstance.current = riskChart;
      }
    }
  }, [columnData]);


  return (
    <div style={{ maxWidth: '1200px', margin: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0px' }}>
        <div ref={barChartRef} style={{ flex: 1, height: '200px', marginTop: '50px', marginRight: '10px' }}></div>
        <div ref={pieChartRef} style={{ flex: 1, height: '350px' }}></div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '50px' }}>
        <div ref={columnChartRef} style={{ flex: 1, height: '600px', marginRight: '20px' }}></div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <div ref={riskChartRef} style={{ flex: 1, height: '300px', marginTop: '30px', marginBottom: '20px' }}></div>
      </div>
    </div>
  );
  
};

export default DataGraph;