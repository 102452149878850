import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './NavBar';
import Home from './Home';
import Predictor from './Predictor';
import StaffPortal from './StaffPortal';
import Dashboard from './Dashboard';
import './App.css';

const Layout = () => {
  const location = useLocation();
  const showNavBar = location.pathname !== '/dashboard';

  return (
    <div>
      {showNavBar && <NavBar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/predictor" element={<Predictor />} />
        <Route path="/staff-portal" element={<StaffPortal />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </div>
  );
};

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;
