import React, { useEffect, useState } from "react";
import { Button, Table, message, Space, Popconfirm, Badge, DatePicker } from "antd";
import axios from "axios";

const CustomTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [allSelectedRowKeys, setAllSelectedRowKeys] = useState(new Set());
  const [tableKey, setTableKey] = useState(0);
  const [filteredDates, setFilteredDates] = useState([]);

  const [filterAndSorter, setFilterAndSorter] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });

  const [tableHeight, setTableHeight] = useState(500);

  useEffect(() => {
    const calculateTableHeight = () => {
      const heightOffset = 300;
      const height = window.innerHeight - heightOffset;

      setTableHeight(height);
    };

    calculateTableHeight();

    window.addEventListener("resize", calculateTableHeight);

    return () => window.removeEventListener("resize", calculateTableHeight);
  }, []);

  const columns = [
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      sorter: (a, b) => a.age - b.age,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      filters: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
      ],
      onFilter: (value, record) => record.gender === value,
    },
    {
      title: "HDL Cholesterol",
      dataIndex: "hdl_cholesterol",
      key: "hdl_cholesterol",
      sorter: (a, b) => a.hdl_cholesterol - b.hdl_cholesterol,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Total Cholesterol",
      dataIndex: "total_cholesterol",
      key: "total_cholesterol",
      sorter: (a, b) => a.total_cholesterol - b.total_cholesterol,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Blood Pressure",
      dataIndex: "blood_pressure",
      key: "blood_pressure",
      sorter: (a, b) => a.blood_pressure - b.blood_pressure,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Smoker",
      dataIndex: "is_smoker",
      key: "is_smoker",
      render: (text) => (text === "Yes" ? "Yes" : "No"),
      filters: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
      onFilter: (value, record) => record.is_smoker === value,
    },
    {
      title: "Risk %",
      dataIndex: "risk",
      key: "risk",
      sorter: (a, b) => a.risk - b.risk,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Record Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortDirections: ["descend", "ascend"],
    }
  ];  

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("http://localhost:8000/api/patients", { withCredentials: true });
      const patients = response.data.patients;
      setData(
        patients.map((patient) => ({
          ...patient,
          key: patient.id,
        }))
      );
      console.log("Data: ", patients);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleResetFiltersAndSort = () => {
    setFilterAndSorter({
      filteredInfo: null,
      sortedInfo: null,
    });

    fetchData();
    setTableKey((prevKey) => prevKey + 1);
  };

  const handleTableChange = (filters, sorter) => {
    setFilterAndSorter({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  const handleDelete = async () => {
    setLoading(true);
    const idsToDelete = Array.from(allSelectedRowKeys);
    try {
      await axios.post("/api/delete_patients", {
        ids: idsToDelete,
      });

      const updatedSelectedRowKeys = new Set(allSelectedRowKeys);
      idsToDelete.forEach((id) => updatedSelectedRowKeys.delete(id));
      setAllSelectedRowKeys(updatedSelectedRowKeys);
      setSelectedRowKeys([...updatedSelectedRowKeys]);
      fetchData();
    } catch (error) {
      console.error("Failed to delete data:", error);
      message.error("Failed to delete data.");
    } finally {
      setLoading(false);
    }
  };

  const rowSelection = {
    selectedRowKeys: Array.from(allSelectedRowKeys), 
    preserveSelectedRowKeys: true,
    onSelect: (record, selected) => {
      const newSelectedRowKeys = new Set(allSelectedRowKeys);
      if (selected) {
        newSelectedRowKeys.add(record.id);
      } else {
        newSelectedRowKeys.delete(record.id);
      }
      setAllSelectedRowKeys(newSelectedRowKeys);
      setSelectedRowKeys(Array.from(newSelectedRowKeys));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      const newSelectedRowKeys = new Set(allSelectedRowKeys);
      changeRows.forEach((row) => {
        if (selected) {
          newSelectedRowKeys.add(row.id);
        } else {
          newSelectedRowKeys.delete(row.id);
        }
      });
      setAllSelectedRowKeys(newSelectedRowKeys);
      setSelectedRowKeys(Array.from(newSelectedRowKeys));
    },
    onChange: (newSelectedRowKeys) => {
    },
  };

  const confirm = (e) => {
    console.log(e);
    handleDelete();
    message.success("Selected records have been deleted.");
  };
  const cancel = (e) => {
    console.log(e);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
        }}
      >
        <Space>
          <Button
            onClick={handleResetFiltersAndSort}
            disabled={loading}
            style={{ marginRight: "16px" }}
          >
            Reset Filters and Sorting
          </Button>
          <Popconfirm
            title="Delete"
            description="Confirm? This action cannot be undone."
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="Cancel"
          >
            <Badge color="red" count={selectedRowKeys.length}>
              <Button
                type="primary"
                disabled={selectedRowKeys.length === 0}
                loading={loading}
                textAlign="center"
              >
                Delete
              </Button>
            </Badge>
          </Popconfirm>
        </Space>
      </div>
      <Table
        key={tableKey}
        className="custom-table-header"
        bordered
        size="middle"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ y: tableHeight }}
        pagination={false}
      />
    </div>
  );
};

export default CustomTable;