import React, { useState, useEffect } from 'react';
import { DatabaseOutlined, DesktopOutlined, AreaChartOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, message, Spin } from 'antd';
import { Navigate, useNavigate } from 'react-router-dom';
import CustomTable from './CustomTable';
import DataView from './DataView';
import DataGraph from './DataGraphs';
import LoginSettings from './LoginSettings';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem('View Stored Data', '1', <DatabaseOutlined />),
  getItem('Data Representation', '2', <DesktopOutlined />),
  getItem('Data Graphs', '3', <AreaChartOutlined />), 
  getItem('Login Settings', '4', <SettingOutlined />), 
  getItem('Log Out', '5', <LogoutOutlined />),
];

const Dashboard = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedKey, setSelectedKey] = useState('1');
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      message.destroy();
      message.error("Access denied, please log in.");
      navigate('/staff-portal');
    } else {
      setIsLoading(false);
    }
  }, [navigate]);

  const findLabelByKey = (key) => {
    const item = items.find(item => item.key === key);
    return item ? item.label : 'Unknown';
  };

  const onSelect = ({ key }) => {
    setSelectedKey(key);
  };

  const renderContent = (key) => {
    switch (key) {
      case '1':
        return <CustomTable />;
      case '2':
        return <DataView />;
      case '3':
        return <DataGraph />;
      case '4':
        return <LoginSettings />
      case '5':
        localStorage.removeItem('token');
        message.destroy()
        message.info("Logged out.")
        navigate('/')
      default:
        return "Content not found";
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!localStorage.getItem('token')) {
    return <Navigate to="/staff-portal" replace />;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} theme="dark" width='250px'>
        <div
          className="logo"
          style={{
            height: '40px',
            margin: '20px',
            background: 'rgba(255, 255, 255, 0)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '20px',
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          DASHBOARD
        </div>
        <div style={{ fontSize: '26px'}}>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} items={items} onSelect={onSelect} />
        </div>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: '#f0f2f5' }} />
        <Content style={{ margin: '0 16px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>{findLabelByKey(selectedKey)}</Breadcrumb.Item>
          </Breadcrumb>
          <div style={{ padding: 24, minHeight: 360, background: '#fff', borderRadius: '4px' }}>
            {renderContent(selectedKey)}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;