import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { PlusCircleOutlined, HomeOutlined, LoginOutlined } from '@ant-design/icons';

const items = [
  {
    label: 'Home',
    key: 'Home',
    icon: <HomeOutlined />,
    path: '/'
  },
  {
    label: 'Predictor',
    key: 'Predictor',
    icon: <PlusCircleOutlined />,
    path: '/predictor'
  },
  {
    label: 'Staff Portal',
    key: 'Staff Portal',
    icon: <LoginOutlined />,
    path: '/staff-portal'
  }  
];

const NavBar = () => {
  const storedCurrent = localStorage.getItem('current');
  const [current, setCurrent] = useState(storedCurrent || 'Home');
  const [loading, setLoading] = useState(true);

  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
    localStorage.setItem('current', e.key);
  };

  useEffect(() => {
    const path = window.location.pathname;
    const matchingItem = items.find(item => item.path === path);
    if (matchingItem) {
      setCurrent(matchingItem.key);
      localStorage.setItem('current', matchingItem.key);
    }
    setLoading(false); // Set loading to false after determining the active key
  }, []);

  return (
    <div className="navbar">
      <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal">
        {items.map(item => (
          <Menu.Item key={item.key} icon={item.icon} className="menu-item">
            <Link to={item.path}>{item.label}</Link>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default NavBar;
